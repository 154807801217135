import { Injectable } from '@angular/core';

export interface Notification {
  userImage: string;
  status: string;
  userName: string;
  title: string;
  createdAt: string;
}

const MESSAGES1: Notification[] = [
  {
    userImage: 'assets/images/users/1.jpg',
    status: 'online',
    userName: 'MX Chip Kit',
    title: 'Device is online!',
    createdAt: '9:30 AM'
  },
  {
    userImage: 'assets/images/users/2.jpg',
    status: 'busy',
    userName: 'Amit Debanath',
    title: 'Prepare for demo.',
    createdAt: '9:10 AM'
  }
];

const MESSAGES2: Notification[] = [
  {
    userImage: 'assets/images/users/1.jpg',
    status: 'online',
    userName: 'Device Kit',
    title: 'Post Action Completed!',
    createdAt: '9:30 AM'
  },
  {
    userImage: 'assets/images/users/2.jpg',
    status: 'busy',
    userName: 'Amit Debanath',
    title: 'Prepare for demo.',
    createdAt: '9:10 AM'
  },
  {
    userImage: 'assets/images/users/2.jpg',
    status: 'away',
    userName: 'Himanshu',
    title: 'Tasks created for v2',
    createdAt: '9:08 AM'
  },
  {
    userImage: 'assets/images/users/4.jpg',
    status: 'offline',
    userName: 'Thomas',
    title: 'New features added.',
    createdAt: '9:00 AM'
  }
];

@Injectable()
export class MessageNotifications {
  public getNotifications(): Notification[] {
    return 1 === 1 ? MESSAGES1 : MESSAGES2;
  }
}
