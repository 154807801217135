import { Component, OnInit, Input } from '@angular/core';
import { Widget, WidgetLayout } from '../../dashboard/models';

@Component({
  selector: 'app-chronos-cockpit',
  templateUrl: './chronos-cockpit.component.html',
  styleUrls: ['./chronos-cockpit.component.scss']
})
@Widget('Chronos Cockpit')
export class ChronosCockpitComponent implements OnInit {
  @Input() public basicSettings: any;
  @Input() public customSettings: any;

  public url: string;
  public linkText: string;

  constructor() {}

  public widgetConfig: any;
  public widgetLayout: WidgetLayout = {};

  public ngOnInit() {
    if (this.customSettings) {
      this.url = this.customSettings.find((x) => x.key === 'url').value;
      this.linkText = this.customSettings.find((x) => x.key === 'linkText').value;
    }
  }
}
