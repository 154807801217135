import { ValidUserParam, UserUsDTO, UserService, WcSettings } from 'chronos-live-client';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserCustomService extends DrawerSidenavService<UserUsDTO> {
  public isRefreshList = new Subject<boolean>();
  public isIconandText = new Subject<boolean>();
  public isIconOnly = new Subject<boolean>();
  public users = new Subject<UserUsDTO>();
  constructor(private client: UserService) {
    super();
  }

  public getUsers(orid: number): Observable<any> {
    return this.client.getUsers(orid);
  }

  public getUsersettingdetails(orid: number, usid: number): Observable<any> {
    const params: UserService.GetUserSettingParams = {
      orid,
      usid
    };
    return this.client.getUserSetting(params);
  }

  public addUser(user: UserUsDTO) {
    const params: UserService.AddUserParams = {
      body: user,
      orid: -1
    };
    return this.client.addUser(params);
  }

  public addUserSettings(orid: number, usid: number, ueid: number, ue: WcSettings) {
    const params: UserService.AddUserSettingsParams = {
      orid,
      usid,
      ueid,
      body: ue
    };
    return this.client.addUserSettings(params);
  }

  public updateUser(user: UserUsDTO) {
    const params: UserService.UpdateUserParams = {
      body: user,
      orid: -1
    };
    return this.client.updateUser(params);
  }

  public updateUserSettings(orid: number, usid: number, ueid: number, ue: WcSettings) {
    const params: UserService.UpdateUserSettingParams = {
      orid,
      usid,
      ueid,
      body: ue
    };
    return this.client.updateUserSetting(params);
  }

  public deleteUser(orid: number, usid: number): Observable<any> {
    const params: UserService.RemoveUserParams = {
      usid,
      orid
    };
    return this.client.removeUser(params);
  }

  public activeInactiveUser(orid: number, user: UserUsDTO): Observable<any> {
    user.userrolesUr = null;
    const params: UserService.ActiveInactiveUserParams = {
      body: user,
      orid
    };

    return this.client.activeInactiveUser(params);
  }

  public getUserGroups(orid: number): Observable<any> {
    return this.client.getUserGroups(orid);
  }

  public getUserRoles(orid: number): Observable<any> {
    return this.client.getUserRoles(orid);
  }

  public getLanguages(orid: number): Observable<any> {
    return this.client.getLangauges(orid);
  }

  public getTimeZones(orid: number): Observable<any> {
    return this.client.getTimeZones(orid);
  }

  /**
   * get user information
   * @param orId organizationId
   * @param userId userId
   */
  public getUser(orId: number, userId: number): Observable<UserUsDTO> {
    const params: UserService.GetUserParams = {
      orid: orId,
      usid: userId
    };
    return this.client.getUser(params);
  }

  public validUsername(orId: number, user: ValidUserParam): Observable<boolean> {
    const params: UserService.IsValidUserNameParams = {
      orid: orId,
      body: user
    };
    return this.client.isValidUserName(params);
  }

  public validUserEmail(orId: number, user: ValidUserParam): Observable<boolean> {
    const params: UserService.IsValidUserEmailParams = {
      orid: orId,
      body: user
    };
    return this.client.isValidUserEmail(params);
  }
}
