<h3 mat-dialog-title>
  <b>
    {{ translationKeyPrefix + data.dialogTitle.replace(' ', '') | translate }}
  </b>
</h3>
<div mat-dialog-content>
  <h4>{{ translationKeyPrefix + 'NavigationSettings' | translate }}:</h4>
  <form [formGroup]="formCustomSetting">
    <mat-radio-group formControlName="navigationSetting">
      <mat-radio-button value="icononly" class="m-r-10" color="primary">
        {{ translationKeyPrefix + 'IconOnly' | translate }}
      </mat-radio-button>
      <mat-radio-button value="iconandtext" class="m-r-10" color="primary">
        {{ translationKeyPrefix + 'IconAndText' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>
<br />
<br />
<div mat-dialog-actions>
  <div>
    <button type="submit" mat-raised-button color="primary" (click)="onSave()">
      {{ translationKeyPrefix + 'Save' | translate }}
    </button>
    <button type="reset" mat-raised-button color="primary" (click)="onNoClick()">
      {{ translationKeyPrefix + 'Cancel' | translate }}
    </button>
  </div>
</div>
