<div class="row no-gutters table-check-element">
  <div class="table-check-element__item table-check-element__item--expanded produced-material">
    <p-table [value]="traceabilityItem" [columns]="columns" class="produced-material-table">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let column of columns">
            <th [style.width]="column.width" [style.textAlign]="column.textAlign">{{ column.label | translate }}</th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [ngClass]="rowData.isVirtualContainer ? 'dummy-article-row' : ''">
          <td class="table-text">
            {{ rowData.productionDate | utcToLocal | date: 'dd.MM.yyyy' }}
          </td>
          <td class="table-text">
            <span *ngIf="rowData.isVirtualContainer" class="warning-icon">
              <i class="icon icon-warning-solid icon--warning"></i>
            </span>
            <span>{{ rowData.containerIdentification }}</span>
            <span *ngIf="rowData.targetBobbinNumber">#{{ rowData.targetBobbinNumber }}</span>
            <span *ngIf="rowData.targetOuterNumber">#{{ rowData.targetOuterNumber }}</span>
          </td>
          <td class="text-right">
            {{ rowData.producedMaterialSequenceNumber }}
          </td>
          <td class="text-right">
            {{ rowData.bomGoodQuantity | formatQuantity: true }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseId }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseLocationId }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-template #noData>
      <span>-</span>
    </ng-template>
  </div>
</div>
