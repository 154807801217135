import { Component, OnInit } from '@angular/core';
import { WorkCenterService } from 'chronos-core-client';
import { TelemetryService } from 'chronos-panda-client';
import { AuthService } from 'chronos-live-client';
import moment from 'moment-mini';

@Component({
  selector: 'lib-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  public name: string;
  public token = 'xxx';

  constructor(private workCenterService: WorkCenterService, private telemetryService: TelemetryService, private authService: AuthService) {}

  public ngOnInit(): void {
    this.workCenterService.getWorkCenter(-455).subscribe((workCenter) => (this.name = workCenter.name));

    const query = {
      measurement: '',
      wellKnownSignal: 'speed',
      resource: '-455',
      from: moment.utc().subtract(20, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
      to: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
      aggFunction: 'MEAN',
      groupBy: '',
      resultPoints: '200'
    };

    this.telemetryService
      .apiTelemetryPost({
        body: query
      })
      .subscribe((result) => {
        console.info('telemetry', result);
      });
  }
}
