export const authActionQueryType = {
  ActiveDirLogin: 'ad-login',
  ActiveDirSuccess: 'success',
  ActiveDirLogout: 'ad-logout',
  ActiveDirLogoutCallback: 'logout-callback'
};

export const urlQueryParameter = {
  Action: 'action',
  ReturnUrl: 'returnUrl'
};

export const globalVariable = {
  CurrentUserObject: 'currentUserObject',
  SynAuthExpiry: 'syn-auth-expiry',
  AuthExpiry: 'auth-expiry'
};
