import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

export class Excel {
  public generateExcelForMasterData(excelData: { data: any; headers?: string[]; sheets: string[] }): void {
    const header = excelData.headers;
    const data = excelData.data;
    const workSheets = excelData.sheets;

    const workbook = new Workbook();

    workSheets.forEach((s: any) => {
      if (s != null) {
        const workSheet = workbook.addWorksheet(s);
        const headerRow = workSheet.addRow(header);

        headerRow.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          };
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          };
        });

        data.forEach((d: any) => {
          if (d.includes(s)) {
            workSheet.addRow(d);
          }
        });
      }
    });

    this.generateExcel(workbook, 'Master_Data');
  }

  public generateExcel(workbook: Workbook, excelFileName: string) {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelFileName);
    });
  }
}
