import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoWidgetComponent } from './demo-widget/demo-widget.component';
import { ChronosSharedModule } from 'chronos-shared';

@NgModule({
  declarations: [DemoWidgetComponent],
  imports: [
    CommonModule,
    // SampleSharedModule,
    // DemoSharedComponent,
    ChronosSharedModule
  ]
})
export class SampleModule {}
