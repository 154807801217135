import { map } from 'rxjs/operators';
import { AuthenticationService } from './../services/custom/authentication.service';
import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'chronos-live-client';
import { applicationPaths } from '../services/custom/application-path.constant';
import { globalVariable } from '../services/custom/authentication.constant';

export const authGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router: Router = inject(Router);
  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const navigationService: NavigationService = inject(NavigationService);

  if (state && state.url && (state.url.indexOf('speedmonitor') > -1 || state.url.indexOf('machinechart') > -1)) {
    return true;
  }
  const currentUser = authenticationService.currentUserValue;
  const relativePath = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
  if (currentUser) {
    sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(currentUser));
    authenticationService.currentUserSubject.next(currentUser);
    return navigationService.validateNavigation(relativePath).pipe(
      map((isValid) => {
        if (isValid) {
          return true;
        } else {
          router.navigate([applicationPaths.ActiveDirDenied], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        }
      })
    );
  } else {
    authenticationService.logout();
    router.navigate([applicationPaths.ActiveDirLogin], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
};
