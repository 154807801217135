import { Component, OnInit, OnDestroy } from '@angular/core';
import { Widget } from '../../dashboard/decorators/widget.decorator';
import { SpeedMonitorViewMode } from 'chronos-shared';
import { Observable, Subscription } from 'rxjs';
import { WorkCenterCachedService, WorkCenterData } from 'chronos-core-client';
import { OrganizationsService } from 'chronos-live-client';
import { KPIInfoWithOrder } from 'projects/chronos-live/src/app/services/custom/_models/kpi-info';
import { ILocalization } from '../../../services/custom/interface/ILocalization.interface';

@Widget('Speed Monitor - My Machine')
@Component({
  selector: 'app-speed-monitor-my-machine',
  templateUrl: './speed-monitor-my-machine.component.html',
  styleUrls: ['./speed-monitor-my-machine.component.scss']
})
export class SpeedMonitorMyMachineComponent implements OnInit, OnDestroy, ILocalization {
  private activatedSubscriptions: Subscription[] = [];
  public screenMode: SpeedMonitorViewMode = SpeedMonitorViewMode.FullScreen;
  public externalWorkCenterId: string;
  public workCenterId: number;
  public displayTime: number = null;
  public workCenterList: WorkCenterData[];
  public kpisList: KPIInfoWithOrder[] = null;
  public translationKeyPrefix: string;

  constructor(private workCenterCachedService: WorkCenterCachedService, public organizationsService: OrganizationsService) {
    this.translationKeyPrefix = 'SPEED_MONITOR_MY_MACHINE.';
  }

  public ngOnInit(): void {
    const activeSubscription = this.organizationsService.getActiveOrganizationForSpeedMonitor(0).subscribe((result) => {
      if (result) {
        this.externalWorkCenterId = result.orname;
        const workcenterSubscription = this.getWorkCenterList().subscribe((workCenterList) => {
          if (workCenterList) {
            this.workCenterList = workCenterList;
            this.workCenterList.forEach((element) => {
              if (this.externalWorkCenterId) {
                if (element.externalWorkCenterId === this.externalWorkCenterId.toString()) {
                  this.workCenterId = element.id;
                }
              }
            });
          }
        });
        this.activatedSubscriptions.push(workcenterSubscription);
      }
    });
    this.activatedSubscriptions.push(activeSubscription);
  }

  public ngOnDestroy(): void {
    this.activatedSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  public getWorkCenterList(): Observable<WorkCenterData[]> {
    return this.workCenterCachedService.workCenterGetWorkCenterList();
  }
}
