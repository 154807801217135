import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'va-mat-table',
  templateUrl: './va-mat-table.component.html',
  styleUrls: ['./va-mat-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VaMatTableComponent {
  constructor() {}
}
