import { HttpStateService } from './http-state.service';
import { Input, Component, OnInit } from '@angular/core';
import { IHttpState } from './http-state';
import { HttpProgressState } from './http-progress-state.enum';

@Component({
  selector: 'app-http-spinner',
  templateUrl: './http-spinner.component.html',
  styleUrls: ['./http-spinner.component.scss']
})
export class HttpSpinnerComponent implements OnInit {
  public loading = false;
  @Input() public filterBy: string[] | null = null;
  constructor(private httpStateService: HttpStateService) {}

  /**
   * receives all HTTP requests and filters them by the filterBy
   * values provided
   */
  public ngOnInit() {
    this.httpStateService.state.subscribe((progress: IHttpState) => {
      if (progress && progress.url) {
        if (!this.filterBy) {
          this.loading = progress.state === HttpProgressState.start ? true : false;
        } else if (this.filterBy.some((x) => progress.url.indexOf(x) !== -1)) {
          this.loading = progress.state === HttpProgressState.start ? true : false;
        }
      }
    });
  }
}
