import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { MatSliderModule } from '@angular/material/slider';
import { LogoUiSettingsComponent } from './logo/logo-settings.component';
import { SimpleComponent } from './simple/simple.component';

@NgModule({
  declarations: [LogoComponent, LogoUiSettingsComponent, SimpleComponent],
  imports: [FormsModule, CommonModule, MatSliderModule]
})
export class WidgetDemo2Module {}
