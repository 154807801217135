import { Injectable } from '@angular/core';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { OrganizationOr, GeneralSettings, GeneralSettingsService, OrganizationsService } from 'chronos-live-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsCustomService extends DrawerSidenavService<OrganizationOr> {
  public organizationSettings = new Subject<OrganizationOr>();
  public isGeneralSettingAddedorUpdated = new Subject<boolean>();
  public isRefreshList = new Subject<boolean>();
  public isListLoaded = new BehaviorSubject<boolean>(false);
  public contentMargin = new BehaviorSubject<number>(null);

  constructor(private generalSettingsService: GeneralSettingsService, private organizationsService: OrganizationsService) {
    super();
  }

  public getOrganizations(orid: number): Observable<Array<OrganizationOr>> {
    return this.generalSettingsService.getOrganizations(orid);
  }

  public getOrganizationswithGeneralSettings(orid: number): Observable<Array<OrganizationOr>> {
    return this.generalSettingsService.getOrganizationswithGeneralSettings(orid);
  }
  public getGeneralSettings(orid: number): Observable<OrganizationOr> {
    return this.generalSettingsService.getGeneralSettings(orid);
  }

  public addorUpdateGeneralSettings(generalSettings: GeneralSettings): Observable<OrganizationOr> {
    return this.generalSettingsService.addorUpdateGeneralSettings(generalSettings);
  }

  public deleteGeneralSetting(orid: number) {
    return this.generalSettingsService.deleteGeneralSettings(orid);
  }
}
