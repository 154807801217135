import { Injectable } from '@angular/core';
import { ChronosSharedConfiguration, ApiAuthorizationConfig } from 'chronos-shared';
import { WebSocketClientService } from 'chronos-core-client';
import { ApmService } from '@elastic/apm-rum-angular';

interface AuthConfig {
  authority?: string;
  clientId?: string;
  scope?: string;
  responseType?: string;
}

interface FrontendConfig {
  apm: any;
  pandaRootUrl?: string;
  authentication?: AuthConfig;
}

@Injectable()
export class AppInitService {
  constructor(
    private chronosSharedConfiguration: ChronosSharedConfiguration,
    private webSocketClientService: WebSocketClientService,
    private authConfig: ApiAuthorizationConfig,
    private apmService: ApmService
  ) {}

  public async Init(): Promise<any> {
    // deliberately avoid using Angular HTTP here to not trigger any implicit default configurations
    const frontendConfigResponse = await fetch(`${this.chronosSharedConfiguration.liveRootUrl}/api/v1/FrontendConfig`);

    // when the core service is down, the frontend configuration cannot be fetched
    // give a message to the user to inform about the issue
    if (frontendConfigResponse.status !== 200) {
      document.children[0].innerHTML =
        '<div style="display: grid">' +
        '  <span>Failed to load frontend configuration</span>' +
        '  <span>Please reload the page later!</span>' +
        '  <span>&nbsp;</span>' +
        `  <span>${frontendConfigResponse.status} ${frontendConfigResponse.statusText}</span>` +
        `  <span>URL: ${frontendConfigResponse.url}'</span>` +
        '</div>';

      // reload page every minute to check if the service is back online
      setTimeout(() => {
        window.location.reload();
      }, 60000);
    }

    const frontendConfig: FrontendConfig = await frontendConfigResponse.json();

    // initialize APM first to ensure all errors are properly reported
    this.initializeApm(frontendConfig.apm);

    if (frontendConfig.authentication) {
      this.initializeAuthentication(frontendConfig.authentication);
    }

    this.initializeUrls(frontendConfig);

    await this.initializeSignalR();
  }

  private initializeUrls(frontendConfig: FrontendConfig) {
    if (frontendConfig.pandaRootUrl) {
      this.chronosSharedConfiguration.pandaRootUrl = frontendConfig.pandaRootUrl.replace(/\/$/, '');
    }
  }

  private initializeApm(apmConfig: any) {
    const defaults = {
      enabled: false,
      serviceName: 'chronos-live',
      logLevel: 'warn'
    };
    const config = { ...defaults, ...apmConfig };

    if (config.enabled) {
      this.apmService.init(config);
    }
  }

  private initializeSignalR() {
    return this.webSocketClientService.startConnection();
  }

  private initializeAuthentication(fetchedAuthConfig: AuthConfig) {
    this.authConfig.authority = fetchedAuthConfig.authority ?? this.authConfig.authority;
    this.authConfig.clientId = fetchedAuthConfig.clientId ?? this.authConfig.clientId;
    this.authConfig.responseType = fetchedAuthConfig.responseType ?? this.authConfig.responseType;
    this.authConfig.scope = fetchedAuthConfig.scope ?? this.authConfig.scope;
  }
}
