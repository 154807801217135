import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ColumnSorterService, ColumnInfo } from './column-sorter.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'va-mat-table-column-sorter, button[va-mat-table-column-sorter]',
  templateUrl: './column-sorter.component.html',
  styleUrls: ['./column-sorter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSorterService]
})
export class ColumnSorterComponent implements OnInit, AfterViewInit {
  @Output()
  public columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output()
  public columnInfoChange: EventEmitter<ColumnInfo[]> = new EventEmitter<ColumnInfo[]>();
  @Input()
  public columns: string[];
  @Input()
  public columnNames: string[];
  @Input()
  public saveName?: string;
  @Input()
  public columnInfo: ColumnInfo[];

  constructor(private elementRef: ElementRef, private columnSorterService: ColumnSorterService) {}

  public ngOnInit() {
    this.emitColumns();
  }

  public ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'va-mat-button-no-input';
  }

  public columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.columnInfo, event.item.data.columnIndex, event.currentIndex);
    this.emitColumns();
  }

  public toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo.find((col) => col.id === columnId);
    colFound.hidden = !colFound.hidden;
    this.emitColumns();
  }

  private emitColumns() {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(this.columnInfo.filter((colInfo) => !colInfo.hidden).map((colInfo) => colInfo.id));
      this.columnInfoChange.emit(this.columnInfo);
      // if (saveColumns) {
      //   this.columnSorterService.saveColumnInfo(this.columnInfo, this.saveName);
      // }
    });
  }
}
