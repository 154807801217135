import { Component } from '@angular/core';
import { Widget, WidgetLayout, WidgetSettings } from '../../dashboard/models';

@Widget('Demo')
@Component({
  selector: 'app-demo-widget',
  templateUrl: './demo-widget.component.html',
  styleUrls: ['./demo-widget.component.scss']
})
export class DemoWidgetComponent implements WidgetSettings {
  constructor() {}
  public widgetConfig: any;
  public widgetLayout: WidgetLayout;
}
