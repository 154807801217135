<h3 mat-dialog-title>
  <b>
    {{ translationKeyPrefix + 'ChangePassword' | translate }}
  </b>
</h3>

<div mat-dialog-content>
  <form [formGroup]="formChangePassword" (ngSubmit)="changePassword()">
    <p>
      <i>
        {{ translationKeyPrefix + 'ChangePasswordSuggestionMessage' | translate }}
      </i>
    </p>

    <h4>
      {{ translationKeyPrefix + 'NewPassword' | translate }}
    </h4>
    <mat-form-field>
      <mat-label>{{ translationKeyPrefix + 'NewPassword' | translate }}</mat-label>
      <input matInput type="password" formControlName="newPassword" />
      <div *ngIf="submitted || f.newPassword.errors">
        <small
          *ngIf="formChangePassword.controls['newPassword'].hasError('required') && formChangePassword.controls['newPassword'].touched"
          class="text-danger support-text"
        >
          New password is required.
        </small>
      </div>
    </mat-form-field>
    <h4>Confirm Password:</h4>
    <mat-form-field>
      <mat-label>{{ translationKeyPrefix + 'ConfirmPassword' | translate }}</mat-label>
      <input matInput type="password" formControlName="repeatPassword" />

      <div *ngIf="submitted || f.repeatPassword.errors">
        <small
          *ngIf="
            formChangePassword.controls['repeatPassword'].hasError('required') && formChangePassword.controls['repeatPassword'].touched
          "
          class="text-danger support-text"
        >
          Confirm password is required.
        </small>
        <div *ngIf="f.repeatPassword.errors.mustMatch" class="text-danger support-text">Passwords must match</div>
      </div>
    </mat-form-field>
  </form>
</div>
<br />

<div mat-dialog-actions>
  <button type="submit" mat-raised-button color="primary" (click)="changePassword()">Change Password</button>
  <button type="reset" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
</div>
