export const applicationPaths = {
  DefaultLoginRedirectPath: '/',
  SymLogin: 'authentication/login',
  SymLogOut: 'authentication/logout',
  ActiveDirLogin: '/auth/ad-login',
  ActiveDirSuccess: '/auth/success',
  ActiveDirDenied: '/auth/error',
  ActiveDirLogout: '/auth/ad-logout',
  ActiveDirLogoutCallback: '/auth/logout-callback',
  Dashboard: '/dashboard',
  anonymousDynamicPath: 'dyn'
};
