import { Injectable } from '@angular/core';
import {
  AclAccessRequest,
  AclAccessRoleList,
  AclfunctionAf,
  AcloperationAo,
  AclroleAr,
  AclRoleService,
  AuthFunctionOperation,
  AuthWidgetOperation,
  PageAccessDetails,
  PageWidgetDetail,
  RoleDataAccessCustom,
  RoledataaccessRa,
  UxpageUp,
  UxwidgetUw,
  WidgetFunctionOperation
} from 'chronos-live-client';
import { Observable, of, ReplaySubject } from 'rxjs';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService extends DrawerSidenavService<AclAccessRoleList> {
  public functionOperations$: ReplaySubject<Array<AuthFunctionOperation>>;
  public widgetsToAccess$: ReplaySubject<Array<AuthWidgetOperation>>;

  constructor(private aclRoleService: AclRoleService, private authenticationService: AuthenticationService) {
    super();
    this.functionOperations$ = new ReplaySubject<Array<AuthFunctionOperation>>(1);
    this.widgetsToAccess$ = new ReplaySubject<Array<AuthWidgetOperation>>(1);
  }

  public getAclAccessRoles(orid: number): Observable<AclAccessRoleList[]> {
    return this.aclRoleService.getAclAccessRoles(orid);
  }

  public getRoleDataAccessByRole(arid: number): Observable<RoleDataAccessCustom> {
    return this.aclRoleService.getRoleDataAccessByRole(arid);
  }

  public getAccessOfComponent(pagename: string): Observable<Array<PageAccessDetails>> {
    return this.aclRoleService.getAccessOfComponent(pagename);
  }

  public getAllRolesByOrganization(orid: number): Observable<Array<AclroleAr>> {
    return this.aclRoleService.getAclRoleNamesByOrganization(orid);
  }

  public checkAuthorization(functionName: string): Observable<boolean> {
    return this.aclRoleService.checkAuthorization(functionName);
  }

  public getAllFunctionNamesByWidget(uwid: number): Observable<Array<AclfunctionAf>> {
    return this.aclRoleService.getAllAclFunctionNamesByWidget(uwid);
  }

  public getWidgetFunctionAndEmptyOperations(uwid: number): Observable<Array<WidgetFunctionOperation>> {
    return this.aclRoleService.getWidgetFunctionAndEmptyOperations(uwid);
  }

  public getWidgetFunctionAndOperationsWithValue(uwid: number, arid: number): Observable<Array<WidgetFunctionOperation>> {
    const params: AclRoleService.GetWidgetFunctionAndOperationsWithValueParams = {
      arid,
      uwid
    };
    return this.aclRoleService.getWidgetFunctionAndOperationsWithValue(params);
  }

  public getAllAclPageNames(): Observable<Array<UxpageUp>> {
    return this.aclRoleService.getAllAclPagesNames();
  }

  public getAllAclOperations(): Observable<Array<AcloperationAo>> {
    return this.aclRoleService.getAllAclOperations();
  }

  public getAllAclWidgets(upids: Array<number>): Observable<Array<UxwidgetUw>> {
    return this.aclRoleService.getAllAclWidgets(upids);
  }

  public getAllAclPageWidgets(upids: Array<number>): Observable<Array<PageWidgetDetail>> {
    return this.aclRoleService.getAllAclPageWidgets(upids);
  }

  public getAllAclPageWidgetsWithRole(upids: Array<number>, arid: number): Observable<Array<PageWidgetDetail>> {
    const params: AclRoleService.GetAllPageWidgetsOfRoleParams = {
      body: upids,
      arid
    };
    return this.aclRoleService.getAllPageWidgetsOfRole(params);
  }
  public getApiWidgetOperationse(arid: number): Observable<PageWidgetDetail> {
    return this.aclRoleService.getApiWidgetOperations(arid);
  }

  public getAllAclFunctions(): Observable<Array<AclfunctionAf>> {
    return this.aclRoleService.getAllAclFunctions();
  }

  public updateAclAccess(updatedItem: AclAccessRoleList): Observable<boolean> {
    return this.aclRoleService.updateAclAccessRole(updatedItem);
  }

  public addAccess(newItem: AclAccessRequest): Observable<boolean> {
    return this.aclRoleService.addAccess(newItem);
  }

  public updateAccess(newItem: AclAccessRequest): Observable<boolean> {
    return this.aclRoleService.updateAccess(newItem);
  }

  public deleteAccess(arid: number): Observable<boolean> {
    return this.aclRoleService.deleteAccess(arid);
  }

  public addRoleDataAccess(list: Array<RoledataaccessRa>): Observable<boolean> {
    return this.aclRoleService.addRoleDataAccess(list);
  }

  public updateRoleDataAccess(item: RoleDataAccessCustom): Observable<boolean> {
    return this.aclRoleService.updateRoleDataAccess(item);
  }

  public getAclAccessDataForEdit(arid: number, orid: number): Observable<Array<AclAccessRoleList>> {
    const params: AclRoleService.GetAclAccessDataForEditParams = {
      arid,
      orid
    };
    return this.aclRoleService.getAclAccessDataForEdit(params);
  }

  public getAccessData(arid: number, orid: number): Observable<AclAccessRequest> {
    const params: AclRoleService.GetAccessDataParams = {
      arid,
      orid
    };
    return this.aclRoleService.getAccessData(params);
  }

  public getAclWidgets(): Observable<Array<UxwidgetUw>> {
    return this.aclRoleService.getAclWidgets();
  }

  public getRoleDataAccessList(): Observable<Array<RoleDataAccessCustom>> {
    return this.aclRoleService.getRoleDataAccessList();
  }

  public getRoleDataAccess(arid: number): Observable<Array<RoledataaccessRa>> {
    return this.aclRoleService.getRoleDataAccess(arid);
  }

  /**
   * fetch auth function operation
   */
  public fetchAllAuthFunctionOperation(): void {
    if (this.authenticationService.currentUserValue) {
      this.aclRoleService.getAllAuthFunctionOperation().subscribe((result) => {
        this.functionOperations$.next(result);
        this.functionOperations$.complete();
      });
    }
  }
  public fetchAllAuthWidgetOperation(): void {
    if (this.authenticationService.currentUserValue) {
      this.aclRoleService.getAllAuthWidgetFunctionOperation().subscribe((result) => {
        this.widgetsToAccess$.next(result);
        this.widgetsToAccess$.complete();
      });
    }
  }

  /**
   * check access authorization
   * @param path check a
   */
  public checkAccessAuthorization(functionName: string): Observable<boolean> {
    let isAuthorized = false;
    this.getFunctionOperations().subscribe((result) => {
      if (result && result.length > 0) {
        if (result.some((item) => item.afFunc.toLowerCase() === functionName.toLowerCase() && item.access)) {
          isAuthorized = true;
        }
      }
    });

    return of(isAuthorized);
  }

  public checkWidgetAccessAuthorization(widgetName: string): Observable<boolean> {
    let isAuthorized = false;
    this.getWidgetFunctionOperations().subscribe((result) => {
      if (result && result.length > 0) {
        if (result.some((item) => item.uwname?.toLowerCase() === widgetName?.toLowerCase())) {
          isAuthorized = true;
        }
      }
    });

    return of(isAuthorized);
  }

  /**
   * get function operations
   */
  private getFunctionOperations(): Observable<Array<AuthFunctionOperation>> {
    return this.functionOperations$.asObservable();
  }

  private getWidgetFunctionOperations(): Observable<Array<AuthWidgetOperation>> {
    return this.widgetsToAccess$.asObservable();
  }
}
