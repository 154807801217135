import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChronosEnvironmentListComponent } from './chronos-environment-list.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [ChronosEnvironmentListComponent],
  imports: [CommonModule, MatDividerModule]
})
export class ChronosEnvironmentListModule {}
