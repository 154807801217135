import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChronosSharedModule, SpeedMonitorComponent } from 'chronos-shared';
import { TvComponent } from './tv-component/tv.component';

@NgModule({
  declarations: [TvComponent],
  imports: [
    CommonModule,
    ChronosSharedModule,
    RouterModule.forChild([
      {
        path: 'tv',
        component: TvComponent,
        children: [
          {
            path: 'speedmonitor/:externalWorkCenterId',
            component: SpeedMonitorComponent,
            data: {
              isTvMode: true
            }
          }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class TvModule {}
