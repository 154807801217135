import { Component, OnInit, Input } from '@angular/core';
import { WidgetLayout } from '../dashboard/models/widget-layout';
import { HyperlinkInfo } from '../dashboard/models/HyperlinkInfo';
import { Widget } from '../dashboard/decorators/widget.decorator';

@Component({
  selector: 'app-chronos-environment-list',
  templateUrl: './chronos-environment-list.component.html',
  styleUrls: ['./chronos-environment-list.component.scss']
})
@Widget('Environment List')
export class ChronosEnvironmentListComponent implements OnInit {
  @Input() public basicSettings: any;
  @Input() public customSettings: any;

  public linkList: HyperlinkInfo[] = [];
  public linkBarColor: any;
  public linkGroups = [];

  public widgetConfig: any;
  public widgetLayout: WidgetLayout = {};

  constructor() {}

  public ngOnInit(): void {
    if (this.customSettings) {
      if (this.customSettings.find((x) => x.key === 'hyperlinkInfo').value !== '') {
        this.linkList = JSON.parse(this.customSettings.find((x) => x.key === 'hyperlinkInfo').value);
      }

      if (this.linkList.length !== 0) {
        const groups = new Set(this.linkList.map((item) => item.groupName));
        groups.forEach((g) =>
          this.linkGroups.push({
            name: g,
            values: this.linkList.filter((i) => i.groupName === g)
          })
        );
      }
      this.linkBarColor = this.customSettings.find((x) => x.key === 'linkbarColor').value;
    }
  }
}
