import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BackwardTraceMountedBatch } from 'chronos-core-client';
import { TableHeaderService } from '../../services/table-header.service';

@Component({
  selector: 'lib-order-batch-list',
  templateUrl: './order-batch-list.component.html',
  styleUrls: ['./order-batch-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderbatchlistComponent implements OnInit {
  public isCollapsed = true;
  public columns = [];

  @Input() public traceabilityItem: BackwardTraceMountedBatch | BackwardTraceMountedBatch;
  @Input() public selectedSearchMode: string;

  constructor(private tableHeaderService: TableHeaderService) {}

  public ngOnInit(): void {
    this.initColumns();
  }

  private initColumns(): void {
    this.columns = this.tableHeaderService.getBackwardSearchSubParentHeaders();
  }
}
