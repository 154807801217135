<mat-card appearance="outlined" fxFlex.gt-sm="50" fxFlex.gt-xs="50">
  <mat-card-content class="card-side-drawer-main-content">
    <mat-card-title>{{ translationKeyPrefix + 'UserProfile' | translate }}</mat-card-title>
    <form [formGroup]="userForm">
      <div>
        <div>
          <mat-form-field>
            <mat-label>{{ translationKeyPrefix + 'UserName' | translate }}</mat-label>
            <input matInput type="text" formControlName="usname" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ translationKeyPrefix + 'UserEmail' | translate }}</mat-label>
            <input matInput type="text" formControlName="usemail" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ translationKeyPrefix + 'Language' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator (selectionChange)="onLanguageSelected($event)" formControlName="lcid">
              <mat-option *ngFor="let item of userLanguageList" [value]="item.lcid">{{ item.lcname }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ translationKeyPrefix + 'TimeZone' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator formControlName="tzid">
              <mat-option *ngFor="let item of userTimeZoneList" [value]="item.tzid">{{ item.tzname }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div>
        <button type="submit" mat-raised-button color="primary" class="m-r-20" (click)="updateUser()">
          {{ translationKeyPrefix + 'Update' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" fxFlex.gt-sm="50" fxFlex.gt-xs="50">
  <mat-card-content class="card-side-drawer-main-content">
    <mat-card-title>{{ translationKeyPrefix + 'Utilities' | translate }}</mat-card-title>
    <br />
    <button mat-raised-button color="primary" (click)="openDialog()" class="m-r-20">
      <mat-icon>lock</mat-icon>
      {{ translationKeyPrefix + 'ChangePassword' | translate }}
    </button>
    <!--    <label>
            <span>Select Image</span>
            <input #imageInput type="file" accept="image/*">
        </label> -->
  </mat-card-content>
</mat-card>
