import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MachineChartWidgetComponent } from './machine-chart-widget/machine-chart-widget.component';
import { ChronosSharedModule } from 'chronos-shared';

@NgModule({
  declarations: [MachineChartWidgetComponent],
  imports: [
    CommonModule,
    // SampleSharedModule,
    // DemoSharedComponent,
    ChronosSharedModule
  ]
})
export class MachineChartModule {}
