import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveProductionOrder, KpiValue, WebSocketClientService } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class MachineListService {
  public requestTimeBrowser: string;

  constructor(private webSocketClientService: WebSocketClientService) {}

  public getKpiNotifications(productionOrderId: number): Observable<any> {
    return this.webSocketClientService
      .getNotificationsForTopic<KpiValue[]>(
        `/ProductionOrderKpiList/${productionOrderId}
      `
      )
      .pipe();
  }

  public getActiveProductionOrderNotifications(workCenterId: number): Observable<ActiveProductionOrder> {
    return this.webSocketClientService.getNotificationsForTopic<ActiveProductionOrder>(`/ActiveProductionOrder/${workCenterId}`).pipe();
  }
}
