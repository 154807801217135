import { GeneralSettingsCustomService } from './../../services/custom/general-settings-custom.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';

import { SidenavService } from '../../services/custom/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UserCustomService } from '../../services/custom/user-custom.service';
import { AuthenticationService } from '../../services/custom/authentication.service';
import { WcSettings } from 'chronos-live-client';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StyleService } from '../../services/custom/style.service';
import { HttpStateService } from '../../modules/http-spinner/http-state.service';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy, AfterViewInit {
  public mobileQuery: MediaQueryList;
  public dir = 'ltr';
  public dark: boolean;
  public minisidebar: boolean;
  public showHide: boolean;
  public sidebarOpened: boolean;
  public isMenuShow: boolean;
  public themeColor = '';
  public settings: WcSettings = {};
  public contentMargin = 240;

  public filterBy: string[] = [];

  private _mobileQueryListener: () => void;

  private activeSubscriptions: Subscription[] = [];

  @ViewChild('snav', { static: false }) public sidenav: MatSidenav;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private sidenavService: SidenavService,
    private userCustomService: UserCustomService,
    private authenticationService: AuthenticationService,
    private generalSettingService: GeneralSettingsCustomService,
    private styleService: StyleService,
    private httpStateService: HttpStateService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    // eslint-disable-next-line import/no-deprecated
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.filterBy = this.httpStateService.getServiceUrls();
    this.getOrganizationSettings();
  }

  public ngOnInit(): void {
    this.contentMargin = 240;
    this.userCustomService
      .getUsersettingdetails(this.authenticationService.currentUserValue.orid, this.authenticationService.currentUserValue.usid)
      .subscribe((result) => {
        if (result && result.uedata) {
          this.settings = JSON.parse(result.uedata);
          if (this.settings.value === 'iconandtext') {
            this.userCustomService.isIconandText.next(true);
            this.sidebarOpened = true;
            this.contentMargin = window.innerWidth <= 480 ? 0 : 240;
          } else {
            this.userCustomService.isIconOnly.next(true);
            this.contentMargin = window.innerWidth <= 480 ? 0 : 85;
          }
        }
      });

    this.setContentMargin();
    this.generalSettingService.contentMargin.next(this.contentMargin);

    this.generalSettingService.isRefreshList.subscribe((res) => {
      if (res) {
        const settingSubscription = this.generalSettingService
          .getGeneralSettings(this.authenticationService.currentUserValue.orid)
          .subscribe((result) => {
            if (result) {
              this.generalSettingService.organizationSettings.next(result);

              // set theme
              if (result.orSettings) {
                const orgSettings = JSON.parse(result.orSettings);
                this.themeColor = orgSettings.ApplicationTheme;
              }
            }
          });
        this.activeSubscriptions.push(settingSubscription);
      }
    });
  }

  public ngOnDestroy(): void {
    // eslint-disable-next-line import/no-deprecated
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public ngAfterViewInit() {
    this.sidenavService.setSidenav(this.sidenav);
    this.setContentMargin();
    this.generalSettingService.contentMargin.next(this.contentMargin);
    this.changeDetectorRef.detectChanges();
  }

  public setContentMargin(): void {
    this.userCustomService.isIconandText.subscribe((result) => {
      if (result) {
        this.minisidebar = false;
        this.contentMargin = window.innerWidth <= 480 ? 0 : 240;
      }
    });

    this.userCustomService.isIconOnly.subscribe((result) => {
      if (result) {
        this.minisidebar = true;
        this.contentMargin = window.innerWidth <= 480 ? 0 : 85;
      }
    });
  }
  /**
   * get organization settings
   */
  public getOrganizationSettings() {
    this.generalSettingService.getGeneralSettings(this.authenticationService.currentUserValue.orid).subscribe((result) => {
      if (result) {
        this.generalSettingService.organizationSettings.next(result);

        // set theme
        if (result.orSettings) {
          const orgSettings = JSON.parse(result.orSettings);
          this.themeColor = orgSettings.ApplicationTheme;
        }
      }
      if (this.themeColor !== '') {
        this.styleService.setMainTheme(`${this.themeColor}-theme`);
      } else {
        this.styleService.setMainTheme(environment.theme);
      }
    });
  }
}
