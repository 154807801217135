import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ForwardTraceContainer } from 'chronos-core-client';
import { TraceabilitySearchModeOption } from '../../models/traceability.model';
import { TableHeaderService } from '../../services/table-header.service';

@Component({
  selector: 'lib-production-material-list',
  templateUrl: './production-material-list.component.html',
  styleUrls: ['./production-material-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductionmateriallistComponent implements OnInit {
  public isCollapsed = true;

  @Input() public selectedSearchMode: string;
  @Input() public traceabilityItem: ForwardTraceContainer;

  public columns = [];

  constructor(private tableHeaderService: TableHeaderService) {

  }

  public ngOnInit(): void {
    this.initColumns();
  }

  private initColumns(): void {
    if (this.selectedSearchMode === TraceabilitySearchModeOption.ForwardSearch) {
      this.columns = this.tableHeaderService.getChildTableHeaders(this.selectedSearchMode);
    }
  }
}
