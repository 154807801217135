import { NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import { ProfileMenuItems } from './profile-menu-items/profile-menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { MessageNotifications } from './message-notifications/message-notifications';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
  ],
  providers: [MenuItems, ProfileMenuItems, MessageNotifications]
})
export class SharedModule { }
