import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  public parse(url: string): UrlTree {
    /*
     * only normalize the path portion of the URL since query or fragment content
     * may be case sensitive, e.g. for login callback containting authorization code values
     */
    const parts = url.split(/(\?|#)/);
    parts[0] = parts[0].toLowerCase();
    const normalizedUrl = parts.join('');
    return super.parse(normalizedUrl);
  }
}
