export const environment = {
  production: true,
  googleMapApiKey: 'AIzaSyD99rZY3ElDabTIKiP2EC8WNn7n4JNzoxI',
  apiURL: 'https://euweapiapp01.azurewebsites.net',
  // signalrBaseUrl: 'https://euwefunction03.azurewebsites.net/api/',
  signalrBaseUrl: '/core/ChangeNotificationHub',
  theme: 'mm-theme',
  desktopAppTitle: 'MM Packaging',
  mobileAppTitle: 'MM Packaging',
  defaultLocale: 'enUS',
  liveApiUrl: '/live',
  coreApiUrl: '/core',
  pandaApiUrl: '/panda'
};
