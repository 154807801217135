import { LoginUser, AuthService, UserUsDTO, AuthResponse } from 'chronos-live-client';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from './_models/user';
import { Router } from '@angular/router';
import { applicationPaths } from './application-path.constant';
import { globalVariable } from './authentication.constant';
import { ConstantsService } from '../common/constants.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  private refreshTokenTimeout;
  constructor(private autoAuthService: AuthService, private router: Router, private constantService: ConstantsService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem(globalVariable.CurrentUserObject)));
  }

  private userSubject: Subject<User> = new BehaviorSubject<User>(null);
  public login(userName: string, password: string): Observable<User> {
    const loginUserObject: LoginUser = {
      usemail: userName,
      password
    };
    this.autoAuthService.authenticate(loginUserObject).subscribe((result) => {
      const user: User = {
        userId: result.userId,
        orid: result.orid,
        usid: result.usid,
        response: result.response,
        locale: result.locale
      };
      this.userSubject.next(user);
    });

    return this.userSubject.asObservable();
  }

  public get isSynAuthTokenExpired(): boolean {
    const synAuthExpiry = sessionStorage.getItem(globalVariable.SynAuthExpiry) ?? null;
    if (synAuthExpiry) {
      return this.constantService.CurrentUnixTimeWithBuffer <= Number(synAuthExpiry) ? false : true;
    }
    return true;
  }

  public getUserAuth(): Observable<AuthResponse> {
    return this.autoAuthService.getUserAuth();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public logout() {
    this.stopRefreshTokenTimer();
    sessionStorage.removeItem(globalVariable.CurrentUserObject);
  }

  public logoutAndRedirect() {
    this.logout();
    this.router.navigate([applicationPaths.ActiveDirLogout]);
  }

  public getUserDetails(usid: number, orid: number): Observable<any> {
    const params: AuthService.GetAllUserDetailsParams = {
      usid,
      orid
    };
    return this.autoAuthService.getAllUserDetails(params);
  }

  public updatePassword(orid: number, password: string, usid: number) {
    const params: AuthService.ChangeUserPasswordParams = {
      orid,
      password,
      usid
    };
    return this.autoAuthService.changeUserPassword(params);
  }

  public updateUser(orid: number, us: UserUsDTO) {
    const params: AuthService.UpdateUserSettingsParams = {
      orid,
      body: us
    };
    return this.autoAuthService.updateUserSettings(params);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
