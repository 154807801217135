<lib-speed-monitor
  [mode]="screenMode"
  *ngIf="workCenterId"
  [workCenterId]="workCenterId"
  [kpisList]="kpisList"
  [displayTime]="displayTime"
></lib-speed-monitor>
<div *ngIf="!workCenterId" class="no-access">
  {{ translationKeyPrefix + 'NO_WORKCENTER_ASSIGNED_MESSAGE' | translate }}
</div>
