import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS1 = [
  {
    state: 'profile',
    name: 'Profile',
    type: 'link',
    icon: 'account_box'
  }
];

const MENUITEMS2 = [
  {
    state: 'settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings'
  },
  {
    state: 'profile',
    name: 'Profile',
    type: 'link',
    icon: 'account_box'
  }
];

@Injectable()
export class ProfileMenuItems {
  public getMenuItem(): Menu[] {
    return 1 === 1 ? MENUITEMS1 : MENUITEMS2;
  }
}
