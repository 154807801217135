import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { WcSettings } from 'chronos-live-client';
import { UserCustomService } from '../../services/custom/user-custom.service';
import { AuthenticationService } from '../../services/custom/authentication.service';
import { ILocalization } from '../../services/custom/interface/ILocalization.interface';

@Component({
  selector: 'app-customsetting-dialog',
  templateUrl: './customsetting-dialog.component.html',
  styleUrls: ['./customsetting-dialog.component.scss']
})
export class CustomSettingDialogComponent implements OnInit, ILocalization {
  public formCustomSetting: UntypedFormGroup;
  public settings: WcSettings = {};
  public editmode = false;
  public ueid: number;
  public translationKeyPrefix: string;

  constructor(
    public dialogRef: MatDialogRef<CustomSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private userCustomService: UserCustomService,
    private authenticationService: AuthenticationService
  ) {
    this.translationKeyPrefix = 'Notifications.CustomSettings.';
  }
  public ngOnInit() {
    this.formCustomSetting = this.formBuilder.group({
      navigationSetting: ['']
    });
    this.userCustomService
      .getUsersettingdetails(this.authenticationService.currentUserValue.orid, this.authenticationService.currentUserValue.usid)
      .subscribe((result) => {
        if (result != null) {
          this.editmode = true;
          this.ueid = result.ueid;
          this.settings = JSON.parse(result.uedata);
          this.formCustomSetting.controls.navigationSetting.setValue(this.settings.value);
        }
      });
  }

  public onSave() {
    if (this.editmode) {
      this.settings.key = 'navigationSetting';
      this.settings.value = this.formCustomSetting.get('navigationSetting').value;
      this.userCustomService
        .updateUserSettings(
          this.authenticationService.currentUserValue.orid,
          this.authenticationService.currentUserValue.usid,
          this.ueid,
          this.settings
        )
        .subscribe(() => {
          if (this.settings.value === 'iconandtext') {
            this.userCustomService.isIconandText.next(true);
          }
          if (this.settings.value === 'icononly') {
            this.userCustomService.isIconOnly.next(true);
          }
        });
    } else {
      this.settings.key = 'navigationSetting';
      this.settings.value = this.formCustomSetting.get('navigationSetting').value;
      this.userCustomService
        .addUserSettings(
          this.authenticationService.currentUserValue.orid,
          this.authenticationService.currentUserValue.usid,
          0,
          this.settings
        )
        .subscribe(() => {});
    }
    this.dialogRef.close();
  }
  public onNoClick(): void {
    this.dialogRef.close();
  }
}
