import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TraceabilitySearchModeOption } from '../../models/traceability.model';
import { BackwardTraceBomLine, ForwardTraceOrder } from 'chronos-core-client';
import { TableHeaderService, TableHeader } from '../../services/table-header.service';
@Component({
  selector: 'lib-production-order-list',
  templateUrl: './production-order-list.component.html',
  styleUrls: ['./production-order-list.component.scss', '../../../../../assets/icons/style.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductionorderlistComponent implements OnInit {
  @Input() public selectedSearchMode: string;
  public columns: TableHeader[];
  @Input() public forwardSearchData: ForwardTraceOrder[];
  @Input() public backwardSearchData: BackwardTraceBomLine[];
  public isForwadSearchMode = true;

  constructor(private tableHeaderService: TableHeaderService) {}

  public ngOnInit(): void {
    this.columns = this.tableHeaderService.getParentTableHeaders(this.selectedSearchMode);
    this.isForwadSearchMode = this.selectedSearchMode === TraceabilitySearchModeOption.ForwardSearch ? true : false;
  }

  public getIconClass(articleClassification: string): string {
    switch (articleClassification.toLowerCase()) {
      case 'sheet':
      case 'product':
      case 'pallet':
        return 'icon-pallet';
      case 'reel':
        return 'icon-roll';
      case 'liquid':
        return 'icon-drop';
      case 'container':
        return 'icon-container';
      case 'other':
        return 'icon-other';
      default:
        return '';
    }
  }
}
