import { User } from './../../../services/custom/_models/user';
import { AuthenticationService } from './../../../services/custom/authentication.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidenavService } from '../../../services/custom/sidenav.service';
import { ProfileMenuItems } from '../../../shared/profile-menu-items/profile-menu-items';
import { MessageNotifications, Notification } from '../../../shared/message-notifications/message-notifications';
import { UserCustomService } from '../../../services/custom/user-custom.service';
import { GeneralSettingsCustomService } from '../../../services/custom/general-settings-custom.service';
import { OrganizationFilterService } from '../../../services/custom/organization-filter.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ILocalization } from '../../../services/custom/interface/ILocalization.interface';
import { version } from '@version';
import * as Highcharts from 'highcharts/highstock';

enum Theme {
  MM = 'mm',
  Tieto = 'tieto'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit, OnDestroy, ILocalization {
  public contentMargin: number;
  public oldMargin: number;
  public version = version;
  public desktopAppTitle: string;
  public mobileAppTitle: string;
  public userDisplayName: string;
  public notifications: Notification[];
  public applicationLogo;
  public isMobileView = false;

  private activeSubscriptions: Subscription[] = [];
  public translationKeyPrefix: string;

  /**
   * @param sidenav The Sidenav Service depenedency to toggle side nav in full.component
   * @param profileMenuItems The service for configurable menu items on click of user profile
   */
  constructor(
    public profileMenuItems: ProfileMenuItems,
    public messageNotificatons: MessageNotifications,
    private sidenav: SidenavService,
    private authenticationService: AuthenticationService,
    private userService: UserCustomService,
    private organizationFilterService: OrganizationFilterService,
    private generalSettingService: GeneralSettingsCustomService
  ) {
    this.translationKeyPrefix = 'Header.';
  }

  public ngOnDestroy(): void {
    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public onResize(event) {
    if (event.target.innerWidth <= 450) {
      this.isMobileView = true;
    }
  }
  public ngOnInit(): void {
    this.setContentMargin();

    const currentUser = this.authenticationService.currentUserValue;
    this.getUserInformation(currentUser);

    const settingSubscription = this.generalSettingService.organizationSettings.subscribe((result) => {
      if (result) {
        this.applicationLogo =
          JSON.parse(result.orSettings)?.ApplicationTheme === Theme.MM
            ? 'assets/images/logos/logo-mm.png'
            : 'assets/images/logos/Tieto_MainScreen_WhiteLogo.png';
        this.desktopAppTitle = result.orapplicationtext ? result.orapplicationtext : environment.desktopAppTitle;
        this.mobileAppTitle = result.orapplicationtext ? result.orapplicationtext : environment.mobileAppTitle;
      }
    });

    this.activeSubscriptions.push(settingSubscription);
  }

  public setContentMargin() {
    const marginSub = this.generalSettingService.contentMargin.subscribe((margin) => {
      if (margin) {
        this.contentMargin = margin;
        this.oldMargin = this.contentMargin;
      }
    });
    this.activeSubscriptions.push(marginSub);

    const iconOnlySub = this.userService.isIconOnly.subscribe((iconOnly) => {
      if (iconOnly) {
        this.contentMargin = this.isMobileView ? 0 : 85;
        this.oldMargin = this.contentMargin;
        this.updateChart();
      }
    });
    this.activeSubscriptions.push(iconOnlySub);

    const iconandTextSub = this.userService.isIconandText.subscribe((iconandText) => {
      if (iconandText) {
        this.contentMargin = this.isMobileView ? 0 : 240;
        this.oldMargin = this.contentMargin;
        this.updateChart();
      }
    });
    this.activeSubscriptions.push(iconandTextSub);
  }

  public toggleSidenav() {
    this.sidenav.toggle();
    if (this.contentMargin === 240 || this.contentMargin === 85) {
      this.contentMargin = 0;
    } else if (this.contentMargin === 0) {
      this.contentMargin = this.oldMargin;
    }
    this.updateChart();
  }

  public updateChart() {
    Highcharts.charts.forEach((chart) => {
      if (chart !== undefined) {
        chart.update(
          {
            chart: {
              width: window.innerWidth - this.contentMargin
            }
          },
          true
        );
      }
    });
  }

  public signOut() {
    this.organizationFilterService.selectedOridSubject.next(0);
    this.authenticationService.logoutAndRedirect();
  }

  public getUserInformation(currentUser: User): void {
    if (currentUser) {
      this.userService.getUser(currentUser.orid, currentUser.usid).subscribe({
        next: (result) => {
          if (result) {
            this.userDisplayName = result.usname;
          }
        }
      });
    }
  }
}
