export enum DrawerSidenavState {
  Closed = 1,
  Edit = 2,
  AddNew = 3,
  Readonly = 4
}

import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

export class DrawerSidenavService<T> {
  // Anyone can subscribe to this subject and the component can update or change itself depending
  // upon the value of state.
  public drawerSidenavState: Subject<DrawerSidenavState> = new Subject();

  public selectedItem: T = null;
  public updatedOrAddedItem: T = null;

  private drawerSidenav: MatSidenav;

  constructor() {}

  // Set the Drawer from the component containing Drawer in ngAfterViewInit() life cycle hook.
  // This is the first step to start using DrawerSidenav
  public setDrawerSidenav(sidenav: MatSidenav) {
    this.drawerSidenav = sidenav;
  }

  // Sets the state or mode of the DrawerSidenav.
  // The state must be set only after setting/updating the respective property.
  // Eg. To Edit, set the selectedItem property and then set the DrawerSidenavState to DrawerSidenavState.Edit
  public setDrawerSidenavState(drawerSidenavState: DrawerSidenavState) {
    this.drawerSidenavState.next(drawerSidenavState);

    // also, Show/Hide the Drawer if the state is not closed or similar.
    if (drawerSidenavState === DrawerSidenavState.Closed) {
      this.closeDrawerSidenav();
    } else {
      this.openDrawerSidenav();
    }
  }

  // Opens the Drawer without changing the state. (The Drawer has to be set before it could be opened)
  public openDrawerSidenav() {
    return this.drawerSidenav.open();
  }

  // Closes the Drawer without changing the state
  public closeDrawerSidenav() {
    return this.drawerSidenav.close();
  }
}
