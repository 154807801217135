<div class="table-parameters__body pad-lr">
  <div class="qa-parameters__element row no-gutters">
    <div class="col">
      <div class="table-check-element__item table-check-element__item--expanded order-batch">
        <p-table [value]="traceabilityItem" [columns]="columns" dataKey="internalBatchId" [responsive]="true" class="order-batch-table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th class="table-icon-th"></th>
              <ng-container *ngFor="let column of columns">
                <th [style.width]="column.width" [style.textAlign]="column.textAlign">{{ column.label | translate }}</th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded">
            <tr>
              <td>
                <a href="#" [pRowToggler]="rowData" *ngIf="rowData?.containers?.length">
                  <i
                    [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"
                    class="table-parameters__exp-col-icon"
                  ></i>
                </a>
              </td>
              <td class="table-text">
                {{ rowData.internalBatchId }}
              </td>
              <td class="table-text">
                {{ rowData.externalBatchId }}
              </td>
              <td class="table-text">
                {{ rowData.consumptionDate | utcToLocal | date : 'dd.MM.yyyy' }}
              </td>

              <td class="table-text pallet-icon-padding">
                <span class="primary-material__icon">
                  <!-- <i class="icon icon-pallet"></i> -->
                  <span class="value pallet-value">{{ rowData.itemCount }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
            <tr *ngIf="rowData?.containers?.length">
              <td [attr.colspan]="columns.length + 1" class="second-level">
                <lib-consumption-batch-list
                  [orderBatchItem]="rowData.containers"
                  [selectedSearchMode]="selectedSearchMode"
                ></lib-consumption-batch-list>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
