import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class OrganizationFilterService {
  //// public selectedOrganization = new BehaviorSubject<OrganizationOr>(null);
  public selectedOridSubject = new BehaviorSubject<number>(null);

  /**
   * Initializes a new instance of Organization Filter Service,
   * Injecting the dependecy of Authentication Service
   */
  constructor(private authenticationService: AuthenticationService) {
    this.selectedOridSubject.next(this.authenticationService.currentUserValue.orid);
  }

  public get selectedOrid(): number {
    return this.selectedOridSubject.value;
  }
}
