<div fxLayout="row wrap">
  <div
    fxFlex.gt-lg="47"
    fxFlex.gt-md="47"
    fxFlex.gt-sm="100"
    fxFlex.gt-xs="100"
    fxFlex="100"
    class="divider"
    [ngStyle]="{ 'border-left-color': linkBarColor }"
  >
    <div *ngFor="let group of linkGroups" class="groups">
      <div *ngFor="let link of linkList">
        <div
          fxFlex.gt-lg="47"
          fxFlex.gt-md="47"
          fxFlex.gt-sm="100"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="linkList"
          *ngIf="link.groupName === group.name"
        >
          <a href="{{ link.linkUrl }}" target="_blank">{{ link.linkText }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
