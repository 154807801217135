import { NgModule } from '@angular/core';
import { HttpSpinnerComponent } from './http-spinner.component';
import { DemoMaterialModule } from '../../demo-material-module';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [HttpSpinnerComponent],
  imports: [CommonModule, DemoMaterialModule],
  exports: [HttpSpinnerComponent]
})
export class HttpSpinnerModule {}
