import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClockComponent } from './clock/clock.component';
import { LinesComponent } from './lines/lines.component';

@NgModule({
  declarations: [ClockComponent, LinesComponent],
  imports: [CommonModule]
})
export class WidgetDemoModule {}
