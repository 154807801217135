import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BackwardTraceContainer } from 'chronos-core-client';
import { PipesModule } from '../../../../pipes/pipes.module';
import { TableHeaderService } from '../../services/table-header.service';
@Component({
  selector: 'lib-consumption-batch-list',
  templateUrl: './consumption-batch-list.component.html',
  styleUrls: ['./consumption-batch-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsumptionbatchlistComponent implements OnInit {
  public isCollapsed = true;

  @Input() public orderBatchItem: BackwardTraceContainer;
  @Input() public selectedSearchMode: string;

  constructor(private utcToLocal: PipesModule,
              private tableHeaderService: TableHeaderService) {}

  public columns = [];

  public ngOnInit(): void {
    this.initColumns();
  }

  private initColumns(): void {
    this.columns = this.tableHeaderService.getChildTableHeaders(this.selectedSearchMode);
  }
}
