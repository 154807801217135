<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{ minisidebar: minisidebar }">
  <!-- [ngClass]="themeColor" -->
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <app-http-spinner [filterBy]="filterBy"></app-http-spinner>
  <app-header></app-header>

  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }"
    autosize
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ==============================================================  -->
    <mat-sidenav
      #snav
      id="snav"
      class="pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
    >
      <app-sidebar class="app-sidebar"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content #sidenavcontent class="page-wrapper" [ngStyle]="{ 'margin-left.px': contentMargin }">
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
