import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControlOptions, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../services/custom/authentication.service';
import { ILocalization } from '../../services/custom/interface/ILocalization.interface';

@Component({
  selector: 'app-changepassword-dialog',
  templateUrl: './changepassword-dialog.component.html',
  styleUrls: ['./changepassword-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit, ILocalization {
  public formChangePassword: UntypedFormGroup;
  public usid: number;
  public crid: number;
  public submitted = false;
  public translationKeyPrefix: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.translationKeyPrefix = 'ChangePasswordDialog.';
  }

  public ngOnInit() {
    this.formChangePassword = this.formBuilder.group(
      {
        newPassword: [null, [Validators.required]],
        repeatPassword: [null, [Validators.required]]
      },
      { validator: this.checkIfMatchingPasswords('newPassword', 'repeatPassword') } as AbstractControlOptions
    );
  }
  public get f() {
    return this.formChangePassword.controls;
  }

  public checkIfMatchingPasswords(controlName: string, matchingControlName: string) {
    return (formChangePassword: UntypedFormGroup) => {
      const control = formChangePassword.controls[controlName];
      const matchingControl = formChangePassword.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public onNoClick() {
    this.dialogRef.close();
  }

  public changePassword() {
    this.submitted = true;
    if (this.formChangePassword.invalid) {
      return;
    }
    const addedOrEditedItem = this.formChangePassword.value;
    this.authenticationService
      .updatePassword(
        this.authenticationService.currentUserValue.orid,
        addedOrEditedItem.newPassword,
        this.authenticationService.currentUserValue.usid
      )
      .subscribe(() => {});
    this.dialogRef.close();
  }
}
