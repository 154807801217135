import { AuthenticationService } from '../../../services/custom/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Notification } from '../../../shared/message-notifications/message-notifications';
import { UserCustomService } from '../../../services/custom/user-custom.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserUsDTO } from 'chronos-live-client';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../../../notifications/changepassword-dialog/changepassword-dialog.component';
import { ILocalization } from '../../../services/custom/interface/ILocalization.interface';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../services/custom/_models/user';
import { globalVariable } from '../../../services/custom/authentication.constant';

@Component({
  selector: 'app-user-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, ILocalization {
  public users: UserUsDTO;
  public desktopAppTitle: string;
  public mobileAppTitle: string;
  public userDisplayName: string;
  public notifications: Notification[];
  public userForm: UntypedFormGroup;
  public usid: number;
  public userLanguageList: any;
  public userTimeZoneList: any;
  public translationKeyPrefix: string;
  /**
   * @param sidenav The Sidenav Service depenedency to toggle side nav in full.component
   * @param profileMenuItems The service for configurable menu items on click of user profile
   */
  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private userManagementService: UserCustomService,
    private translateService: TranslateService
  ) {
    this.translationKeyPrefix = 'Profile.';
  }
  public ngOnInit() {
    this.userForm = this.formBuilder.group({
      usname: [''],
      usemail: [{ value: '', disabled: true }],
      lcid: [''],
      tzid: ['']
    });

    this.getUserDetails();

    this.userManagementService.getLanguages(this.authenticationService.currentUserValue.usid).subscribe((lc) => {
      this.userLanguageList = lc;
    });

    this.userManagementService.getTimeZones(this.authenticationService.currentUserValue.usid).subscribe((tz) => {
      this.userTimeZoneList = tz;
    });
  }
  public getUserDetails() {
    this.authenticationService
      .getUserDetails(this.authenticationService.currentUserValue.usid, this.authenticationService.currentUserValue.orid)
      .subscribe((result) => {
        this.users = result;
        this.userForm.reset(this.users[0]);
      });
  }

  public onLanguageSelected(event) {
    this.translateService.setDefaultLang(event.value);
  }

  public updateUser() {
    const addedOrEditedItem = this.userForm.value as UserUsDTO;
    this.users[0].usname = addedOrEditedItem.usname;
    this.users[0].lcid = addedOrEditedItem.lcid;
    this.users[0].tzid = addedOrEditedItem.tzid;
    this.authenticationService.updateUser(this.authenticationService.currentUserValue.orid, this.users[0]).subscribe((result) => {
      if (result) {
        this.setUpdatedUserSettings();
      }
    });
  }

  public setUpdatedUserSettings() {
    this.authenticationService.getUserAuth().subscribe((result) => {
      const user: User = {
        userId: result.userId,
        orid: result.orid,
        usid: result.usid,
        response: result.response,
        locale: result.locale
      };

      sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(user));
      this.authenticationService.currentUserSubject.next(user);
    });
  }

  public openDialog() {
    this.usid = this.users[0].usid;
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '600px',
      height: '500px',
      data: { usid: this.usid, IsNoButtonRequired: true }
    });
  }
}
