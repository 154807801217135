import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  public readonly activate: string = 'Activate';
  public readonly deactivate: string = 'Deactivate';
  public readonly defaultDateTimeFormat = 'dd.MM.yyyy HH:mm';
  public readonly defaultTimeFormat = 'hh:mm';
  public readonly defaultDateFormat = 'dd.MM.yyy';
  public readonly timezone = 'GMT';
  public readonly mapAttributes: string[] = ['Latitude', 'Longitude'];
  public readonly LATITUDE: string = 'Latitude';
  public readonly LONGITUDE: string = 'Longitude';
  public readonly expiryBufferTime: number;
  public readonly IMPORT_FILE_TYPE: string[] = ['xlsx'];
  public readonly EXCEL_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor() {
    this.expiryBufferTime = 60;
  }

  /**
   * get date time stamp
   */
  public static getDateTimeStamp(numberOfDays: number): Date {
    const date = new Date();
    date.setDate(new Date().getUTCDate() + numberOfDays);

    return new Date(date.toLocaleString());
  }

  public get CurrentUnixTime(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  public get CurrentUnixTimeWithBuffer(): number {
    return this.CurrentUnixTime + this.expiryBufferTime;
  }
}
