import 'reflect-metadata';
export enum Editor {
  text,
  myNumber,
  date,
  color,
  checkbox,
  toggle,
  dropdown,
  hyperlinkInfo,
  kpiList
}

export enum BasicSettings {
  title,
  subtitle,
  color
}

export enum NavigationEnum {
  dashboard = 'Dashboard',
  hierarchyManagement = 'Hierarchy Management',
  HierarchyBrowser = 'Hierarchy Browser',
  configuration = 'Configuration'
}

export class FieldDescription {
  constructor(public editor: Editor, public title: string, public optional: boolean) {}
}

export function Field(editor: Editor, title: string = null, optional: boolean = false): PropertyDecorator {
  return (target, key) => {
    const controlText = title ? title : key.toString();
    Reflect.defineMetadata('field', new FieldDescription(editor, controlText, optional), target, key);
    const fields = Reflect.getMetadata('fields', target) || [];
    if (!fields.includes(key)) {
      fields.push(key);
    }
    Reflect.defineMetadata('fields', fields, target);
  };
}

// export class RegisteredWidget {
//     constructor(
//         public componentType: any,
//         // public configDataType: any,
//         // public configUi: any = null,
//     ) { }
// }

// export const registeredWidgets: { [name: string]: RegisteredWidget } = {};
// export function Widget(name: string) {//}, configDataType: any = null, configUi: any = null) {
//     return function (target: Function) {
//         registeredWidgets[name] = new RegisteredWidget(target);//, configDataType, configUi);
//     };
// }
