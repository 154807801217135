import { BehaviorSubject } from 'rxjs';
import { IHttpState } from './http-state';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpStateService {
  public state = new BehaviorSubject<IHttpState>({} as IHttpState);
  public serviceUrls: string[] = [
    '/api/AclRole',
    '/api/Auth',
    '/api/DashboardManagement',
    '/api/Datainput',
    '/api/Features',
    '/api/GeneralSettings',
    '/api/GridSettings',
    '/api/MasterDataSource',
    '/api/MetaModel',
    '/api/Navigation',
    '/api/Organizations',
    '/api/Role',
    '/getusers'
  ];

  constructor() {}

  public getServiceUrls(): string[] {
    return this.serviceUrls;
  }
}
