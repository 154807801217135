import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DemoMaterialModule } from '../demo-material-module';
import { CommonModule } from '@angular/common';
import { ChangePasswordDialogComponent } from './changepassword-dialog/changepassword-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSettingDialogComponent } from './customsetting-dialog/customsetting-dialog.component';
import { AddWidgetLinkDialogComponent } from './addwidgetlink-dialog/addwidgetlink-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfirmDialogComponent, ChangePasswordDialogComponent, CustomSettingDialogComponent, AddWidgetLinkDialogComponent],
  exports: [ConfirmDialogComponent, ChangePasswordDialogComponent, AddWidgetLinkDialogComponent],
  imports: [DemoMaterialModule, CommonModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class NotificationModule {}
