import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChronosCockpitComponent } from './chronos-cockpit/chronos-cockpit.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ChronosCockpitComponent],
  exports: [
    // ChronosCockpitComponent
  ],
  imports: [CommonModule, FlexLayoutModule]
})
export class ChronosCockpitModule {}
